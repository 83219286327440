<template>
  <v-container id="category" fluid tag="section">
    <v-alert color="red lighten-2" dark v-if="showNameError">
      {{ showErrorName }}
    </v-alert>
    <v-form ref="form">
      <v-container class="py-2">
        <v-row>
          <v-col cols="6" md="6">
            <v-text-field
              label="Name"
              class="purple-input"
              v-model="name"
              :rules="inputRule"
              :disabled="onlyView"
            />
          </v-col>
          <v-col cols="6" md="6">
            <v-textarea
              class="purple-input"
              label="Description"
              v-model="description"
              :rules="inputRule"
              :disabled="onlyView"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="6">
            <v-text-field
              label="Video URL"
              class="purple-input"
              v-model="url"
              :rules="inputRule"
              :disabled="onlyView"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-left">
            <v-btn
              type="button"
              color="primary"
              class="mr-0"
              @click="submit"
              :loading="loading"
            >
              {{
                onlyView == true
                  ? "Back"
                  : editRecipe == true
                  ? "Update"
                  : "Add"
              }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
export default {
  props: ["id", "action"],
  data() {
    return {
      showError: false,
      showErrorData: null,
      editRecipe: null,
      onlyView: false,
      loading: false,
      inputRule: [(v) => !!v || "Field is required"],
      name: "",
      description: "",
      url: "",
      showNameError: false,
      showErrorName: null
    };
  },
  methods: {
    getRecipeData(id) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "recipe/edit/" + id)
        .then((response) => {
          if (response.status == 200) {
            this.name = response.data.recipe.name;
            this.description = response.data.recipe.description;
            this.url = response.data.recipe.url;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submit() {
      this.showNameError = false;
      if (this.$refs.form.validate()) {
        this.loading = true;
        let data = {
          id: this.id,
          name: this.name,
          description: this.description,
          url: this.url
        };
        if (this.onlyView) {
          this.$router.push({ name: "Recipes" });
        } else if (this.editRecipe == true) {
          axios
            .post(process.env.VUE_APP_API_BASE_URL + "recipe/update", data)
            .then((response) => {
              if (response.status == 200) {
                this.loading = false;
                this.$router.push({ name: "Recipes", params: { edit: true } });
              }
            })
            .catch((error) => {
              this.loading = false;
              if (error.response.data.name != null) {
                this.showNameError = true;
                this.showErrorName = error.response.data.name[0];
              }
            });
        } else {
          axios
            .post(process.env.VUE_APP_API_BASE_URL + "recipe/create", data)
            .then((response) => {
              if (response.status == 200) {
                this.loading = false;
                this.$router.push({ name: "Recipes", params: { add: true } });
              }
            })
            .catch((error) => {
              this.loading = false;
              if (error.response.data.name != null) {
                this.showNameError = true;
                this.showErrorName = error.response.data.name[0];
              }
            });
        }
      }
    },
  },
  mounted() {
    if (this.id != undefined) {
      this.getRecipeData(this.id);
      this.editRecipe = true;
    } else {
      this.editRecipe = false;
    }
    if (this.action == "view") {
      this.onlyView = true;
    }
  },
};
</script>

<style>
</style>